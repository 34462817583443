<template>
  <div>
    <Breadcrumbs
      page_title="article"
      :items="breadcrumbs"
      v-show="!isshowarticleView"
    />

    <v-card
      class="mx-3 my-5 rounded-lg"
      v-show="!isshowarticleView"
      v-if="hasData"
    >
      <template>
        <v-container fluid>
          <v-row class="d-flex">
            <v-col
              v-for="item in items"
              :key="item.id"
              cols="12"
              lg="6"
              md="6"
              sm="12"
              class="border"
            >
              <v-list class="pt-2 pb-0">
                <v-list-item-group>
                  <v-list-item
                    class="pa-0"
                    @click="
                      $router.push({
                        path: '/Library/_subLibrary/_path/_article/articleView',
                        query: { article_id: item.id },
                      })
                    "
                  >
                    <v-col class="pr-0">
                      <v-img
                        :src="item.pictureBackground"
                        class="lessonimg"
                      ></v-img>
                    </v-col>
                    <v-col cols="12" md="7" lg="6">
                      <v-list-item-content style="color: #424242">
                        <v-list-item-title style="font-size: 18px">
                          {{ item.content_Name }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                          {{ item.wordDescription }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-col>
                    <v-col cols="2" style="padding: 0px">
                      <div style="width: 35px; height: 35px" class="lessonIcon">
                        <v-img
                          src="@/assets/images/article_home.svg"
                          width="32"
                          height="35"
                          class="ml-auto my-auto center"
                        />
                      </div>
                    </v-col>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="d-flex justify-end align-center pr-6 pb-1"
                >
                  {{ $t("enddate") + " : " + item.endDateOfContent }}
                </v-col>
              </v-row>
              <div class="test">
                <div class="border1"></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-card>

    <v-card
      class="loadingarticle mx-3 my-5 rounded-lg d-flex justify-center"
      v-else-if="loading"
    >
      <v-row class="mx-3 my-5">
        <v-col cols="6" v-for="n in 4" :key="n">
          <v-skeleton-loader
            class="mx-auto"
            max-height="160"
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="noarticle mx-3 my-5 rounded-lg d-flex justify-center" v-else>
      <div class="text-center nodata">{{ $t("noarticle") }}</div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
export default {
  components: {
    // ArticleView
  },
  computed: {},
  data() {
    return {
      breadcrumbs: [
        {
          text: "library",
          href: "/library",
        },
        {
          text: "article",
        },
      ],
      subName: "",
      items: [],
      libraryName: "",
      loading: true,
      isshowarticleView: false,
      contentName: "",
      contentFile: "",
      wordDescription: "",
      pictureBackground: "",
      link: "",
      pdfUrl: "",
      contentCheck: 0,
      favFlag: "",
      contentID: 0,
      hasData: false,
    };
  },
  mounted() {
    this.getFavourite();
    this.getNotificationCount();

    this.getArticleData();
    this.subName = this.$route.params.subName;
    this.libraryName = localStorage.getItem("libraryName");
    this.$store.commit("savenotificationTransactionIDForArticle", 0);
  },
  methods: {
    async getArticleData() {
      let self = this;
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        topicID: parseInt(localStorage.getItem("libraryId")),
      };
      const res = await axios.post(
        `${self.web_url}Contents/GetArticleData`,
        request
      );

      if (res.data.data != null) {
        self.items = res.data.data.map((v, i) => ({
          ...v,
          no: i + 1,
          endDateOfContent: moment(v.endContentDate)
                .local()
                .format("DD MMM YYYY"),
        }));
        this.loading = false;
        this.hasData = true;
      } else {
        this.loading = false;
        this.hasData = false;
      }
    },
  },
};
</script>

<style scoped>
.border {
  margin: 10px 0px;
}
.test {
  display: flex;
  justify-content: center;
}

.border1 {
  height: 1px;
  width: 95%;
  background-color: #00000027;
}
.lessonimg {
  width: 192px !important;
  height: 130px !important;
  border-radius: 12px;
}
.lessonTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.lessonDes {
  font-size: 14px;
  opacity: 0.6;
}
.lessonIcon {
  margin-left: 0px;
  color: #4fb14e;
  font-size: 30px;
  top: 10px;
  right: 20px;
  position: absolute;
}
.noarticle {
  height: 200px;
  align-items: center;
}
.nodata {
  color: #a4a4a4;
  font-size: 20px !important;
}
.loadingarticle {
  height: 400px;
}
</style>
