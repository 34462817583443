<template>
  <div>
    <Breadcrumbs page_title="video" :items="breadcrumbs" />

    <v-card class="mx-3 my-5 rounded-lg">
      <template>
        <v-container fluid>
          <v-row class="mt-0" v-if="hasData">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="4"
              lg="3"
              v-for="(item, i) in items"
              :key="i"
              class="pl-4 pr-4 pt-0"
            >
              <v-card
                style="
                  border-radius: 16px;
                  background-color: #f0f0f0;
                  box-shadow: unset;
                "
                height="auto"
              >
                <v-card-text
                  justify="center"
                  align="center"
                  style="
                    height: 100%;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 10px;
                    padding-bottom: 5px;
                  "
                >
                  <p
                    class="text-center mb-2"
                    style="font-size: 18px; color: #424242"
                  >
                    {{ item.contentName }}
                  </p>
                  <v-img :src="item.optimizeLink" class="soundimg"></v-img>
                  <div
                    style="height: 135px; overflow-x: hidden; overflow-y: auto"
                    class="overflow-y-auto mt-4"
                  >
                    <v-row
                      v-for="(item1, i) in item.videoList"
                      :key="i"
                      style="cursor: pointer"
                    >
                      <v-col
                        cols="12"
                        sm="6"
                        xs="6"
                        md="9"
                        lg="9"
                        class="pl-1 pr-2 pt-2 pb-1"
                        style="text-align: left"
                      >
                        <div
                          class="contentTextStyle"
                          @click="PlayMP4(item1, item)"
                        >
                          <span style="font-size: 14px">{{
                            item1.content_Name
                          }}</span>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        xs="6"
                        md="3"
                        lg="3"
                        class="pl-2 pr-3 pt-2 pb-2"
                        style="text-align: end"
                      >
                        <v-progress-circular
                          :rotate="-90"
                          :size="30"
                          :width="3"
                          :value="item1.progressTime * 100"
                          color="#4FB14E"
                          class="mx-auto my-auto center playIcon"
                          @click="PlayMP4(item1, item)"
                        >
                          <v-icon
                            style="
                              font-size: 20px;
                              color: #cccccc;
                              padding-left: 3px;
                              padding-top: 2px;
                            "
                            >mdi-play-outline</v-icon
                          >
                        </v-progress-circular>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="d-flex justify-end align-center pt-3 pr-2 pb-1"
                    >
                      {{ $t("enddate") + " : " + item.endDateOfContent }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="mt-0" v-else-if="loading">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="4"
              lg="3"
              class="pl-4 pr-4"
              v-for="n in 3"
              :key="n"
            >
              <v-skeleton-loader
                class="mx-auto"
                style="height: 370px; border: solid 1px #f0f0f0; padding: 10px"
                type="text,image,list-item-two-line"
              ></v-skeleton-loader>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col>
              <div class="text-center nodata">{{ $t("novideo") }}</div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-card>

    <videoPlay
      ref="videoPlay"
      :show="showVideoDialog"
      @close="(showVideoDialog = false), GetVideoData()"
      @updateProgressCircle="updateProgressCircle"
      :videoName="videoName"
      :videoFile="Videolink"
      :contentid="content_id"
      :viewerFlag="viewerFlag"
      :formattedDateTime="formattedDateTime"
      :courseID="courseID"
      :lastWatchedTime="lastWatchedTime"
      :memberID="memberID"
      :video_page="1"
    ></videoPlay>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import videoPlay from "./videoPlay.vue";

export default {
  components: {
    videoPlay,
    // Player,
    // Video,
    // Ui,
    // Scrim,
    // ControlGroup,
    // Controls,
    // ControlSpacer,
    // CurrentTime,
    // EndTime
  },

  data() {
    return {
      viewerFlag: false,
      //formattedDateTime: this.formatDateTime(new Date()),
      formattedDateTime: "",
      showVideoDialog: false,
      videoName: "",
      lastWatchedTime: "",
      courseID: -1, //distinguish from course
      //old
      //request_flag: false,
      current_videoId: 0,
      PlayBtn: true,
      finishVideo: false,
      fullScreen: false,
      content_id: 0,
      duration: -1,
      progress_time: 0,
      centisecond: 0,
      seeking: false,
      actualPlayTime: 0, // work as seconds
      playing: false,
      restart: false, //use for restart the video
      currentTime: 0,
      playback_started: false,
      isFullscreenBefore: false,
      isFullscreen: false,
      exit_dialog: false,
      complete_dialog: false,
      dialogWidth: "800px",
      isFavorite: false,
      userID: "",
      companyID: "",
      memberID: "",
      breadcrumbs: [
        {
          text: "library",
          href: "/library",
        },
        {
          text: "video",
        },
      ],
      items: [],
      loading: true,
      Videolink: "",
      player_dialog: false,
      mPlay: true,
      fullscreenflag: true,
      startTime: "00:00",
      endTime: "00:00",
      hasData: false,
    };
  },
  computed: {
    progressPercentage() {
      return (this.currentTime / this.duration) * 100 + "%";
    },
    player() {
      return this.$refs.player;
    },
  },
  mounted() {
    this.GetVideoData();
    this.getNotificationCount();
    this.getFavourite();
    // Update the dialog width on window resize
    window.addEventListener("resize", this.updateDialogWidth);
    this.updateDialogWidth();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateDialogWidth);
  },

  methods: {
    updateProgressCircle(p) {
      this.items = this.items.map((x) => ({
        ...x,
        progressTime: x.id == p.id ? p.progressTime : x.progressTime,
      }));
    },
    formatDateTime(dateTime) {
      const options = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
      };
      const dateTimeString = dateTime.toLocaleDateString("en-US", options);
      return dateTimeString.replace(/\//g, "-");
    },
    PlayMP4(item, mainitem) {
      let date1 = new Date();
      this.formattedDateTime = this.formatDateTime(date1);
      this.current_videoId = item.id;
      this.viewerFlag = mainitem.viewerFlag;
      this.player_dialog = true;
      this.content_id = item.content_ID;
      this.showVideoDialog = true;
      this.videoName = item.content_Name;
      this.Videolink = item.content_File;
      this.memberID = item.memberID;
      if (item.progressTime == "1") {
        this.lastWatchedTime = 0;
      } else {
        this.lastWatchedTime = item.currentminutes;
      }

      this.$refs.videoPlay.getFavorite(item.favFlag);
      //this.$refs.videoPlay.GetcommendData(item.content_ID, 1);
      this.$refs.videoPlay.GetCommentsForVideo(item.content_ID);
    },
    async GetVideoData() {
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        topicID: parseInt(localStorage.getItem("libraryId")),
      };
      const res = await this.$axios.post(
        `${this.web_urlV5}GuidedLesson/GetVideoData`,
        request
      );

      if (res.data.data.length > 0) {
        this.items = res.data.data.map((v, i) => ({
          ...v,
          no: i + 1,
          endDateOfContent: moment(v.endContentDate)
            .local()
            .format("DD MMM YYYY"),
        }));
        this.loading = false;
        this.hasData = true;
      } else {
        this.loading = false;
        this.hasData = false;
      }
    },
    // Update the dialog width based on screen size
    updateDialogWidth() {
      if (window.innerWidth < 768) {
        this.dialogWidth = "90%";
      } else {
        this.dialogWidth = "800px";
      }
    },
  },
};
</script>

<style scoped>
.responsive-dialog {
  width: 50% !important;
  height: 50% !important;
  max-width: 800px !important;
  max-height: 500px !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.dialog-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.dialog-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Adjust the dialog container width based on screen size */
@media only screen and (max-width: 768px) {
  .dialog-container {
    max-width: 90%;
  }
}

/* Adjust the player width based on screen size */
@media only screen and (max-width: 600px) {
  .responsive-dialog .v-player {
    max-width: 100%;
  }
}
</style>

<style scoped>
.heart-icon-container {
  position: absolute;
  top: 20px;
  right: 24px;
  height: 30px;
  z-index: 1000 !important;
}
.back-icon-container {
  position: absolute;
  top: 20px;
  left: 24px;
  z-index: 1000 !important;
}
.controls-padding {
  padding-left: 18px !important;
  padding-right: 18px !important;
}
.imageShadow {
  display: inline-block;
  text-align: center;
  color: transparent;
  border-radius: 3px !important;
  border: transparent !important;
  background: transparent;
  background: linear-gradient(
    rgb(255, 255, 255, var(--alpha)) 0.3%,
    rgb(255, 255, 255, var(--alpha))
  );
  padding-bottom: 10px;
  background-size: 100% 200%;
}
#ButtonAnimationControls {
  display: inline-block;
  text-align: center;
  color: transparent;
  border-radius: 4px !important;
  border: transparent !important;
  background: transparent;
  background: unset;
  padding-bottom: 12px;
  background-size: 100% 200%;
}
#ButtonAnimationHeart {
  height: 42px;
  display: inline-block;
  text-align: center;
  border-radius: 8px !important;
  background: var(--vm-color-gray-500) !important;
  background-size: 100% 200%;
  padding: 8px 7px;
}
#ButtonAnimationBack {
  height: 42px;
  display: inline-block;
  text-align: center;
  border-radius: 8px !important;
  background: var(--vm-color-gray-500) !important;
  background-size: 100% 200%;
  padding-bottom: 9px;
  padding-top: 7px;
  padding-left: 7px;
  padding-right: 7px;
}
::v-deep .pause-name.v-btn:not(.v-btn--round).v-size--default {
  min-width: 25px;
  min-height: 25px;
  padding-right: 11px;
  padding-left: 11px;
  box-shadow: unset !important;
}
::v-deep .play-name.v-btn:not(.v-btn--round).v-size--default {
  min-width: 23px;
  min-height: 25px;
  padding-right: 10px;
  padding-left: 11px;
  box-shadow: unset !important;
}
::v-deep .fullscreen-name.v-btn:not(.v-btn--round).v-size--default {
  min-width: 25px;
  min-height: 20px;
  padding-right: 5px;
  padding-left: 5px;
  box-shadow: unset !important;
}
</style>

<style scoped>
/* old ui's css */
.soundimg {
  height: 160px;
  object-fit: cover;
  width: 250px;
  border-radius: 8px;
}
.lessonIcon {
  top: 20px;
  position: absolute;
}
.test {
  display: flex;
  justify-content: center;
}

.border1 {
  height: 1px;
  width: 95%;
  background-color: #00000027;
}
.back-button {
  position: absolute;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  cursor: pointer;
  border: 1px solid rgba(66, 66, 66, 0.05);
  border-radius: 5px;
}
.noarticle {
  height: 200px;
  align-items: center;
}
.nodata {
  color: #a4a4a4;
  font-size: 20px !important;
}
.loadingarticle {
  height: 400px;
}
</style>

<style scoped>
.progress-bar {
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  cursor: pointer;
}

.progress {
  height: 100%;
  background-color: #a6cc39;
  width: 0%;
  transition: width 0.1s ease-in-out;
}

.progress-bar__background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.progress-bar__fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #a6cc39;
  transition: width 0.1s linear;
}
.progress-bar__handle {
  position: absolute;
  top: -5px;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #a6cc39;
  transform: translateX(-50%);
  transition: left 0.1s linear;
}
</style>

<style scoped>
.controls {
  display: flex;
  width: 93%;
  height: 78px;
  position: absolute;
  flex-wrap: wrap;
  pointer-events: auto;
  box-sizing: border-box;
  background: var(--vm-color-gray-500) !important;
  /**--vm-color-gray-500  --vm-color-white-700*/
  padding-top: 12px !important;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 8px;
  opacity: 1;
  visibility: visible !important;
  bottom: 10px !important;
}
.player {
  line-height: 1;
}
::v-deep .controls.active {
  opacity: 1;
  visibility: visible;
  justify-content: center !important;
}

.login-phone {
  color: #fff;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.login-phone:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.login-phone:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.login-phone:hover {
  transition: 0.25s;
}
.login-phone:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
.contentTextStyle {
  width: 160px;
  height: 25px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  padding-top: 4px;
}
.playIcon {
  margin-right: 0.4rem !important;
}

::v-deep .overflow-y-auto::-webkit-scrollbar {
  width: 3px !important;
  border-radius: 10px !important;
}
/* Track */
::v-deep .overflow-y-auto::-webkit-scrollbar-track {
  width: 3px !important;
  background: #c0c0c05c;
  border-radius: 10px !important;
}
/* Handle */
::v-deep .overflow-y-auto::-webkit-scrollbar-thumb {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  background-color: transparent;
  border-top: 40px solid #424242;
}
::v-deep .overflow-y-auto {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 8px;
  padding-bottom: 0px;
}
::v-deep .v-dialog--scrollable::-webkit-scrollbar {
  width: 0px !important;
  background: #e6e6e6;
}
::v-deep .v-dialog--scrollable::-webkit-scrollbar-track {
  width: 0px !important;
  background: #e6e6e6;
}
::v-deep .v-dialog--scrollable::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 1px #e6e6e6;
}
</style>

<style scoped>
@media (min-width: 1441px) and (max-width: 2560px) {
  .soundimg {
    height: 160px;
    object-fit: cover;
    width: unset;
    border-radius: 8px;
  }
}
@media (min-width: 1535px) and (max-width: 1836px) {
  .contentTextStyle {
    width: 200px;
    height: 25px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    padding-top: 4px;
    padding-left: 10px;
  }
}
@media (min-width: 1836px) and (max-width: 2243px) {
  .contentTextStyle {
    width: 275px;
    height: 25px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    padding-top: 4px;
    padding-left: 10px;
  }
}
@media (min-width: 2243px) and (max-width: 2560px) {
  .contentTextStyle {
    width: 370px;
    height: 25px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    padding-top: 4px;
    padding-left: 10px;
  }
}
</style>
